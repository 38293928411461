<div data-test-id="results-v2-people-filter-title" class="title pt-3">
  <h1 data-test-id="results-v2-people-filter-searchUsers">{{ label || 'resultsV2.filter.searchUsers' | transloco}}</h1>
</div>
<form data-test-id="results-v2-people-filter-form" *ngIf="!selected; else showSelected">
  <mat-form-field data-test-id="results-v2-people-filter-form-field" class="w-100" appearance="outline">
    <mat-label data-test-id="results-v2-people-filter-search">{{ 'resultsV2.filter.search' | transloco }}</mat-label>
    <input data-test-id="results-v2-people-filter-inp" type="text" matInput [formControl]="searchControl"
      [matAutocomplete]="auto">
    <mat-autocomplete data-test-id="results-v2-people-filter-autocomplete" #auto="matAutocomplete"
      [displayWith]="displayFn">
      <mat-option [attr.data-test-id]="'results-v2-people-filter-option'+i" *ngFor="let option of (filteredOptions | async); let i = index"
        [value]="option" (click)="select(option)">
        <span data-test-id="results-v2-people-filter-name" class="primary-color">{{option.name}}</span>
        <br data-test-id="results-v2-people-filter-br">
        <small data-test-id="results-v2-people-filter-small" class="d-flex">
          <span data-test-id="results-v2-people-filter-synlabId" class="m-1">{{option.synlabId}}</span>
          <span data-test-id="results-v2-people-filter-email" class="m-1 break-all">{{option.email}}</span>
        </small>
      </mat-option>
    </mat-autocomplete>
    <div data-test-id="results-v2-people-filter-search-icon" class="search-icon">
      <ng-container data-test-id="results-v2-people-filter-container" *ngIf="!fetching">
        <img data-test-id="results-v2-people-filter-img" *ngIf="!searchControl.value"
          src="/assets/images/search-primary-icon.svg" alt="search-primary-icon" />
        <img data-test-id="results-v2-people-filter-img2" role="button" (click)="clear()" *ngIf="searchControl.value"
          src="/assets/images/primary-close-icon.svg" alt="primary-close-icon" />
      </ng-container>
      <mat-spinner data-test-id="results-v2-people-filter-spinner" *ngIf="fetching" diameter="18"></mat-spinner>
    </div>
    <mat-error data-test-id="results-v2-people-filter-error">{{ message }}</mat-error>
  </mat-form-field>
</form>

<ng-template data-test-id="results-v2-people-filter-showSelected" #showSelected>
  <mat-form-field data-test-id="results-v2-people-filter-form-field2" class="w-100" appearance="outline">
    <input data-test-id="results-v2-people-filter-input" type="text" role="button" matInput readonly
      [value]="displayFn(selected)" (click)="showDetail()">
    <div data-test-id="results-v2-people-filter-search-icon2" class="search-icon">
      <img data-test-id="results-v2-people-filter-buttonimg" role="button" (click)="clear()"
        src="/assets/images/primary-close-icon.svg" alt="primary-close-icon" />
    </div>
  </mat-form-field>
</ng-template>
