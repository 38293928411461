<div data-test-id="test-result-container" class="container-fluid py-4">
  <section data-test-id="test-result-my-result" class="my-result">
    <div data-test-id="test-result-card" class="card bx-shdw-1">
      <div data-test-id="test-result-body" class="card-body p-0">
        <ul data-test-id="test-result-nav" _ngcontent-goc-c14="" class="nav nav-tabs" id="requisitionTab"
          role="tablist">
          <li data-test-id="test-result-nav-item" class="nav-item">
            <a data-test-id="test-result-myresult" aria-controls="sendte" class="nav-link active" data-toggle="tab" href="#sendte" id="sendte-tab"
              role="tab">My Results</a>
          </li>
        </ul>
        <div data-test-id="test-result-tab-content" class="tab-content" id="requisitionTabContent">
          <div data-test-id="test-result-tab-pane" class="tab-pane fade show active py-3 pb-5" id="sendte"
            role="tabpanel" aria-labelledby="sendte-tab">
            <div data-test-id="test-result-header" class="header d-flex px-3">
              <div data-test-id="test-result-recent-labelcont" class="recent">
                <label data-test-id="test-result-myRecentOrders-label" for="" id="myRecentOrders">My Recent
                  Orders</label>
              </div>
              <div data-test-id="test-result-search-cont"
                class="ml-3 search-box position-relative d-flex align-items-baseline bg-white rounded-3 px-2 py-1 ">
                <span data-test-id="test-result-searchicon" class="fas fa-search pr-1"></span>
                <input data-test-id="test-result-searchinput" type="text" #searchFocus
                  class="search-box__input outline-none border-0" name="searchTerm" [ngModel]="state.searchTerm"
                  (ngModelChange)="changeSearchTerm($event)" placeholder="Search order ID, doctor or tests" />
              </div>
            </div>
            <div data-test-id="test-result-cont-main" class="cont-main">
              <table data-test-id="test-result-myRecentOrders" class="table table-borderless mb-5"
                aria-describedby="myRecentOrders">
                <thead data-test-id="test-result-data-thead" class="bg-color-grey-100">
                  <tr data-test-id="test-result-data-tr">
                    <th data-test-id="test-result-th-orderdate" class="font-color-primary-600 tracking-tight"
                      scope="col" sortable="clientName">Order Date</th>
                    <th data-test-id="test-result-th-orderid" class="font-color-primary-600 tracking-tight" scope="col"
                      sortable="name">Order ID</th>
                    <th data-test-id="test-result-th-requesting" class="font-color-primary-600 tracking-tight"
                      scope="col" sortable="clientName">Requesting
                      Doctor</th>
                    <th data-test-id="test-result-th-status" class="font-color-primary-600 tracking-tight" scope="col"
                      sortable="clientName">Status</th>
                    <th data-test-id="test-result-th-labresult" class="font-color-primary-600 tracking-tight"
                      scope="col" sortable="clientName">Lab Results</th>
                  </tr>
                </thead>
                <tbody data-test-id="test-result-data-table"
                  *ngIf="!state.loading && state.endItemIndex > 0; else loadingTableTemplate">
                  <tr [attr.data-test-id]="'test-result-dateTimeTransaction-td'+i" *ngFor="let result of state.data; let i = index">
                    <td data-test-id="test-result-dateTimeTransaction"
                      class="p-3 font-weight-600 text-black tracking-tighter">
                      {{ result.commonOrder.dateTimeTransaction | date : 'MM-dd-yyyy' : '+0000' }}
                    </td>
                    <td data-test-id="test-result-highlight-td" class="p-3 font-weight-600 text-black tracking-tighter">
                      <ngb-highlight data-test-id="test-result-highlight"
                        [result]="result.observationRequest?.requisitionNumber" [term]="state.searchTerm">
                      </ngb-highlight>
                    </td>
                    <td data-test-id="test-result-searchTerm-td"
                      class="p-3 font-weight-600 text-black tracking-tighter">
                      <ngb-highlight data-test-id="test-result-searchTerm" [result]="result.commonOrder?.provider"
                        [term]="state.searchTerm"></ngb-highlight>
                    </td>
                    <td data-test-id="test-result-status-td" class="p-3 font-weight-600 text-black tracking-tighter">
                      <ng-container data-test-id="test-result-status-container"
                        [ngSwitch]="result.observationRequest?.observationResults?.status">
                        <span data-test-id="test-result-verified" *ngSwitchCase="1"
                          class="bg-color-green-100 font-color-green-800 px-3 py-2 rounded-full">Verified by
                          doctor</span>
                        <span data-test-id="test-result-waiting" *ngSwitchCase="2"
                          class="bg-color-yellow-100 font-color-yellow-800 px-3 py-2 rounded-full">Waiting for
                          results</span>
                        <span data-test-id="test-result-result-received" *ngSwitchCase="3"
                          class="bg-color-primary-100 font-color-primary-700 px-3 py-2 rounded-full">Results
                          received</span>
                        <span data-test-id="test-result-empty2" *ngSwitchDefault
                          class="bg-color-grey-100 font-color-grey-700 px-3 py-2 rounded-full">
                          -
                        </span>
                      </ng-container>
                    </td>
                    <td data-test-id="test-result-view-pdf" class="p-3 font-weight-600 text-black tracking-tighter">
                      <a href="#" data-test-id="test-result-view-pdfhref"> View PDF </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div data-test-id="test-result-tracking-tight-cont"
                class="d-flex justify-content-between align-items-baseline px-3 flex-column flex-lg-row">
                <span data-test-id="test-result-tracking-tight" class="tracking-tight font-weight-600 mb-3 mb-lg-0"
                  *ngIf="!state.loading && state.endItemIndex > 0; else emptySpan;">
                  {{ 'Showing ' + state.startItemIndex + ' to ' + state.endItemIndex + ' out of ' + state.totalCount + '
                  results' }}
                </span>
                <ngb-pagination data-test-id="test-result-pagination" *ngIf="state.endItemIndex > 0" [page]="state.page"
                  [pageSize]="state.pageSize" [maxSize]="10" [collectionSize]="state.totalCount"
                  (pageChange)='onPageChange($event)'>
                  <ng-template data-test-id="test-result-ngbPaginationPrevious" ngbPaginationPrevious><span
                      data-test-id="test-result-less-than" class="fas fa-less-than"></span></ng-template>
                  <ng-template data-test-id="test-result-ngbPaginationNext" ngbPaginationNext><span
                      data-test-id="test-result-greater-than" class="fas fa-greater-than"></span></ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


<ng-template data-test-id="test-result-loadingTableTemplate" #loadingTableTemplate>
  <tbody data-test-id="test-result-loadingTableTemplate-tbody">
    <tr data-test-id="test-result-loadingTableTemplate-tr" class="no-hover" [ngSwitch]="listStatus">
      <td data-test-id="test-result-spinner-td" colspan="5" class="text-center spinner" *ngSwitchCase="1">
        <p data-test-id="test-result-p3" class="py-5">
          <img data-test-id="test-result-spinner" src="assets/images/spinner.gif" alt="spinner"
            class="img-fluid spinner image-spinner" />
        </p>
      </td>
      <td data-test-id="test-result-your-search" colspan="5" class="text-center" *ngSwitchCase="2">
        <p data-test-id="test-result-p2" class="py-4 tbl-msg">Your search gave no result...</p>
      </td>
      <td data-test-id="test-result-norecord" colspan="5" class="text-center" *ngSwitchCase="3">
        <p data-test-id="test-result-p1" class="py-4 tbl-msg">No records found</p>
      </td>
    </tr>
  </tbody>
</ng-template>

<ng-template data-test-id="test-result-emptySpan" #emptySpan>
  <span data-test-id="test-result-emptySpan-span"></span>
</ng-template>
